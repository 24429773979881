.messages {
    margin: 0 auto;
    padding: 30px 15px 0 25px;
    width: 60%;
}

.inputMessageWrite input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
}

.typeMessage { 
    border-top: 1px solid #c4c4c4;
    position: relative;
}

.messageSendButton {
    background: #05728f none repeat scroll 0 0;
    border: medium none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right: 0;
    top: 11px;
    width: 33px;
}

.messaging { 
    padding: 0 0 50px 0;
}

.messageHistory {
    height: 400px;
    overflow: hidden;
}

.messagesContainer {
    height: 100%;
    overflow-y: scroll;
}