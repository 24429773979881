.card {
    min-width: 100px;
    height: 100%;
}

@media (max-width: 576px) {
    .card {
        overflow: visible;
        min-width: 100px;
        height: 100%;
    }
}