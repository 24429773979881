@import "../../theme/tables.scss";

$blackText: #000000;

:global .load-lane > .ant-modal-content {
  background-color: transparent;
}

.card-container > .ant-tabs-card > {
  .ant-tabs-content {
    height: 120px;
    margin-top: -16px;

    > .ant-tabs-tabpane {
      background: #fff;
      padding: 16px;
    }
  }

  .ant-tabs-bar {
    border-color: #fff;

    .ant-tabs-tab {
      border-color: transparent;
      background: transparent;
    }

    .ant-tabs-tab-active {
      border-color: #fff;
      background: #fff;
    }
  }
}

.stopDetails {
  display: block;
  margin-bottom: 40px;

  .stopLocationContainer {
      display: flex;
      margin-bottom: 12px;

      .stopIconContainer {
          width: 30px;
          margin-right: 12px;

          .stopIcon {
              width: 30px;
              height: 30px;
          }
      }

      .stopLocation {
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          color: $blackText;
          flex: 1;
          text-align: left;
      }
  }

  .stopAddress {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $blackText;
      opacity: 0.65;
  }

  .apptDetails {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: $blackText;
      opacity: 0.45;
  }
}