@import "../../theme/forms.scss";
@import "../../theme/tables.scss";

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #ececec;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.loadSummaryContainer {
  // min-height: calc(100vh - 8px);
  // max-height: calc(100vh - 8px);
  height: calc(100vh - 8px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.loadSummary {
  flex: auto;
  overflow-y: auto;
}

.loadSummaryButtonContainer {
  //position: relative;
  display: inline-flex;
  //right: 0;
  width: 100%;
  border-top: 4px solid #e9e9e9;
  padding: 0;
  margin: 0 !important;
  background: #ffffff;
  // align-items: center;
  // justify-content: space-evenly;
  // height: 60px;
  height: fit-content;
}