$darkBackgroundColor: #001529;
$lightBackgroundColor: #FFFFFF;

.content {
    padding: 4px;
    min-height: calc(100vh - 48px);
}

.topLogo {
    height: 24px;
    margin: 16px 16px 16px 0px;
    display: block;
}

.header {
    padding: 0; 
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
}

.leftMenu {
    float: left;
    height: 48px;
    line-height: 48px;
    display: flex;
    align-items: center;
    width: 150px;
    padding-left: 16px;
}

.darkMenu {
    background: #001529;
}

.lightMenu {
    background: #ffffff;
}

.rightMenu {
    width: calc(100% - 150px);
    float: right;
}

.nameAndAccountName {
    line-height: 16px;
    padding: 8px 16px;
    height: 48px;
    align-items: center;
    justify-content: center;
}

.copyright {
    line-height: 20px !important;
    height: 40px !important;
    text-align: center;
    margin: 0;
}

.termsOfService {
    line-height: 20px !important;
    height: 20px !important;
    text-align: center;
    margin: 0;
}