.detailsList {
    width: 100%;
    overflow: hidden;
    border: none;
}

.detailsList table {
    margin-left:auto; 
    margin-right:auto; 
}

.detailsListContainer {
    width: 100%;
    display: flex;
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}