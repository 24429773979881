img { 
    max-width:100%; 
}

.incomingMessage { 
    overflow:hidden; 
    margin:26px 0 26px;
}

.incomingMessageImage {
    display: inline-block;
    width: 6%;
}

.receivedMessage {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
 }

 .receivedMessageWithDate p {
    background: #ebebeb none repeat scroll 0 0;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
}

.timeDate {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.receivedMessageWithDate { 
    width: 57%;
}