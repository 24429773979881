.underConstruction {
  height: 100%;
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
}

.underConstructionContent {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
}