:global .ant-form legend {
    margin-bottom: 8px;
}

:global .ant-form-vertical .ant-form-item {
    padding-bottom: 2px;
}

:global .ant-form-item {
    margin-bottom: 2px;
}