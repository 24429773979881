.invoiceTitle h2, .invoiceTitle h3 {
    display: inline-block;
}

.trNoLine {
    border-top: none;
}

.noLine {
    border-bottom: none;
}

.thickLine {
    border-top: 2px solid;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    color: #212529;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    border-color: grey;
}

.table > thead > tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.table > thead > tr > td {
    vertical-align: top;
    line-height: 1.5;
    padding: 5px;
}

.table > tbody > tr > td {
    vertical-align: top;
    line-height: 1.5;
    padding: 5px;
    border-top: 1px solid #ddd;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}