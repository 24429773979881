@import "../../theme/forms.scss";

:global .customTimelineItem {
    .ant-timeline-item-tail {
        position: absolute;
        top: 30px;
        left: 4px;
        height: calc(100% - 40px);
        border-left: 10px dotted #f0f0f0;
    }

    .ant-timeline-item-head {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 2px solid transparent;
        border-radius: 100px;
    }
}

:global .customTimelineItem-last {
    .ant-timeline-item-tail {
        display: none;
    }

    .ant-timeline-item-head {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 2px solid transparent;
        border-radius: 100px;
    }
}

.buttonContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #ffffff;
    text-align: right;
    z-index: 500;
}