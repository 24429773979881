.dataRow {
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
}

.dataRowLabel {
    padding: 8px 16px;
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
}

.dataRowContent {
    padding: 8px 16px;
}