.actionButtonContainer {
    white-space: nowrap;
    text-align: right;
    display: block;
}

@media (max-width: 576px) {

    .actionButtonContainer {
        display: block;
        width: 100%;
        padding-top: 12px;
        overflow: hidden;
        text-align: left;
    }
}