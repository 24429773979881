$selectedRowBackgroundColor: #23bebb35;
$unverifiedRowBackgroundColor: #fff1f0;
$yellowHighlightBackgroundColor: #ffe58f;
$redHighlightBackgroundColor: #ffccc7;
$blueBackground: #334353;
$whiteBackground: #FFFFFF;
$greenBackground: #F6FFED;
$redBackground: #FFE4E2;
$yellowBackground: #FFFBE6;
$grayBackground: #E9E9E9;
$irisGreen: #23BEBB;
$grayText: #979797;
$blackText: #000000;
$whiteText: #FFFFFF;
$grayBorder: #BFBFBF;

$cancelledBackground: $grayBackground;
$approvedBackground: $redBackground;
$completedBackground: $greenBackground;
$dispatchedBackground: $greenBackground;
$undispatchedBackground: $whiteBackground;
$missingBackground: $redBackground;
$readyToDispatchBackground: $blueBackground;
$selectedBackground: $yellowBackground;
$sentBackground: $greenBackground;
$hoverBackground: #fafafa;

.hidden { display:none; }

.selectedRow {
    background-color: $selectedRowBackgroundColor !important;
    cursor: pointer;
  
    &:hover {
      background-color: $selectedRowBackgroundColor !important;
    }
}

.unverifiedRow {
  background-color: $unverifiedRowBackgroundColor !important;

  &:hover {
    background-color: $unverifiedRowBackgroundColor !important;
  }
}

.expiringSoonRow {
  cursor: pointer;
  background: $yellowHighlightBackgroundColor !important;

  &:hover {
    background: $yellowHighlightBackgroundColor !important;
  }
}

.expiredRow {
  cursor: pointer;
  background: $redHighlightBackgroundColor !important;

  &:hover {
    background: $redHighlightBackgroundColor !important;
  }
}

.cancelledRow {
  background-color: $cancelledBackground !important;
  background: $cancelledBackground !important;

  &:hover {
    background-color: $cancelledBackground !important;
    background: $cancelledBackground !important;
  }
}

.approvedRow {
  background-color: $approvedBackground !important;
  background: $approvedBackground !important;

  &:hover {
    background-color: $approvedBackground !important;
    background: $approvedBackground !important;
  }
}

.completedRow {
  background-color: $completedBackground !important;
  background: $completedBackground !important;

  &:hover {
    background-color: $completedBackground !important;
    background: $completedBackground !important;
  }
}

.sentRow {
  background-color: $sentBackground !important;
  background: $sentBackground !important;

  &:hover {
    background-color: $sentBackground !important;
    background: $sentBackground !important;
  }
}

.missingRow {
  background-color: $missingBackground !important;
  background: $missingBackground !important;

  &:hover {
    background-color: $missingBackground !important;
    background: $missingBackground !important;
  }
}

.dispatchedRow {
  background-color: $dispatchedBackground !important;
  background: $dispatchedBackground !important;

  &:hover {
    background-color: $dispatchedBackground !important;
    background: $dispatchedBackground !important;
  }
}

.readyToDispatchRow {
  background-color: $readyToDispatchBackground !important;
  background: $readyToDispatchBackground !important;
  color: $whiteText !important;

  a {
      color: $whiteText !important;
  }

  div {
      button {
          color: $whiteText !important;

          &:hover {
              color: $whiteText !important;
          }
      }
  }

  &:hover {
      background-color: $readyToDispatchBackground !important;
      background: $readyToDispatchBackground !important;
      color: $whiteText !important;

      a {
          color: $whiteText !important;
      }

      div {
          button {
              color: $whiteText !important;

              &:hover {
                  color: $whiteText !important;
              }
          }
      }
  }
}

.undispatchedRow {
  // background-color: transparent !important;
  // background: transparent !important;

  &:hover {
    background-color: $hoverBackground !important;
    background: $hoverBackground !important;
  }
}

.pendingRow {
  // background-color: transparent !important;
  // background: transparent !important;

  &:hover {
    background-color: $hoverBackground !important;
    background: $hoverBackground !important;
  }
}

.unselectedRow {
  cursor: pointer;
}

.dataTableRow {
  cursor: pointer;
}

.columnNoBreak {
  white-space: nowrap;
}
  
.selectedRow a, .selectedRow a:hover {
  text-decoration: underline;
}
  
.unselectedRow a, .unselectedRow a:hover {
  text-decoration: underline;
}

:global .ant-table-body {
  overflow-x: auto;
}

// :global .ant-drawer-body {
//   padding: 12px;
// }