@import "../../theme/tables.scss";

.card-container > .ant-tabs-card > {
  .ant-tabs-content {
    height: 120px;
    margin-top: -16px;

    > .ant-tabs-tabpane {
      background: #fff;
      padding: 16px;
    }
  }

  .ant-tabs-bar {
    border-color: #fff;

    .ant-tabs-tab {
      border-color: transparent;
      background: transparent;
    }

    .ant-tabs-tab-active {
      border-color: #fff;
      background: #fff;
    }
  }
}

:global .fc-day:hover{
  cursor: pointer;
}