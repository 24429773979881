$blackText: #000000;
$yellowHighlightBackground: rgba(255,220,40,.15);

.fc-load-highlight {
    background-color: $yellowHighlightBackground;
}

.stopDetails {
    display: block;
    margin-bottom: 40px;

    .stopLocationContainer {
        display: flex;
        margin-bottom: 12px;

        .stopIconContainer {
            width: 30px;
            margin-right: 12px;

            .stopIcon {
                width: 30px;
                height: 30px;
            }
        }

        .stopLocation {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
            color: $blackText;
            flex: 1;
            text-align: left;
        }
    }

    .stopAddress {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: $blackText;
        opacity: 0.65;
    }

    .apptDetails {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: $blackText;
        opacity: 0.45;
    }
}

.iconContainer {
    background-color: inherit;
    color: inherit;
    height: 100%;
    width: 60px;
    line-height: 40px;
    display: inline-flex;
    align-items: center;
}

.textContainer {
    background-color: inherit;
    color: inherit;
    height: 100%;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    text-align: left;
    flex: 1;
}

.icon {
    width: 40px !important;
    height: 40px !important;
    margin-right: 12px;
    color: inherit;
}