.titleBlockWithIcon {
    background-color: #ffffff;
    height: 45px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .container {
        display: inline-flex;
        height: 100%;
    
        .iconBlock {
            height: 100%;
            width: 45px;
            margin-right: 20px;
            line-height: 100%;
            background-color: inherit;
            color: #11222F;

            .icon {
                height: 40px;
                width: 40px;
            }
        }

        .titleBlock {
            background-color: inherit;
            color: #334353;
            height: 100%;
            flex: 1;
            line-height: 22px;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
    }
}