$blueBackground: #334353;
$whiteBackground: #FFFFFF;
$greenBackground: #F6FFED;
$redBackground: #FFE4E2;
$yellowBackground: #FFFBE6;
$grayBackground: #E9E9E9;
$irisGreen: #23BEBB;
$grayText: #979797;
$blackText: #000000;
$whiteText: #FFFFFF;
$grayBorder: #BFBFBF;

$dispatchedBackground: $greenBackground;
$undispatchedBackground: $whiteBackground;
$missingBackground: $redBackground;
$readyToDispatchBackground: $blueBackground;
$selectedBackground: $yellowBackground;
$hoverBackground: #fafafa;

.icon {
    width: 40px !important;
    height: 40px !important;
    margin-right: 12px;
    color: inherit;
}

.cell {
    text-align: center;
    white-space: nowrap;

    div {
        white-space: nowrap;
        text-align: center;
    }
}

.dispatchedRow {
    background-color: $dispatchedBackground !important;
    background: $dispatchedBackground !important;
  
    &:hover {
      background-color: $dispatchedBackground !important;
      background: $dispatchedBackground !important;
    }
}

.selectedRow {
    background-color: $selectedBackground !important;
    background: $selectedBackground !important;
  
    &:hover {
      background-color: $selectedBackground !important;
      background: $selectedBackground !important;
    }
}

.missingRow {
    background-color: $missingBackground !important;
    background: $missingBackground !important;
  
    &:hover {
      background-color: $missingBackground !important;
      background: $missingBackground !important;
    }
}

.readyToDispatchRow {
    background-color: $readyToDispatchBackground !important;
    background: $readyToDispatchBackground !important;
    color: $whiteText !important;

    a {
        color: $whiteText !important;
    }

    div {
        button {
            color: $whiteText !important;

            &:hover {
                color: $whiteText !important;
            }
        }
    }
  
    &:hover {
        background-color: $readyToDispatchBackground !important;
        background: $readyToDispatchBackground !important;
        color: $whiteText !important;

        a {
            color: $whiteText !important;
        }

        div {
            button {
                color: $whiteText !important;

                &:hover {
                    color: $whiteText !important;
                }
            }
        }
    }
}

.undispatchedRow {
    // background-color: transparent !important;
    // background: transparent !important;

    &:hover {
      background-color: $hoverBackground !important;
      background: $hoverBackground !important;
    }
}

.stopDetails {
    display: block;
    margin-bottom: 40px;

    .stopLocationContainer {
        display: flex;
        margin-bottom: 12px;

        .stopIconContainer {
            width: 30px;
            margin-right: 12px;

            .stopIcon {
                width: 30px;
                height: 30px;
            }
        }

        .stopLocation {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: $blackText;
            flex: 1;
            text-align: left;
        }
    }

    .stopAddress {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $blackText;
        opacity: 0.65;
    }

    .apptDetails {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: $blackText;
        opacity: 0.45;
    }
}