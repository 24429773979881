/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
    .card {
        width: 90%;
        margin: 10px auto;
    }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media (min-width: 576px) {
    .card {
        width: 90%;
        margin: 10px auto;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
    .card {
        width: 90%;
        max-width: 800px;
        margin: 10px auto;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .card {
        width: 800px;
        margin: 10px auto;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
    .card {
        width: 800px;
        margin: 10px auto;
    }
}
