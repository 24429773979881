@import "../../theme/forms.scss";

.step {
    min-height: calc(100vh - 304px);
    background-color: #ffffff;
    padding: 16px 24px;
}

.stepButtons {
    position: relative;
    display: flex;
    right: 0;
    width: 100%;
    padding: 10px 16px;
    background: #ffffff;
    margin-top: 4px;
    align-items: center;
    justify-content: space-evenly;
    height: 60px;
}