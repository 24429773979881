$selectedCardBackgroundColor: #334353;
$selectedTextColor: #ffffff;
$selectedBorderColor: #23bebb;
$unselectedCardBackgroundColor: #ffffff;
$unselectedTextColor: #000000;
$unselectedBorderColor: #E9E9E9;
$yellowHighlightBackgroundColor: #ffe58f;
$redHighlightBackgroundColor: #ffccc7;

.item {
    padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    .meta {
        width: 100%;
    }

    .avatar {
        margin-top: 4px;
        background: #fff;
    }

    .iconElement {
        font-size: 32px;
    }

    &.read {
        opacity: 0.5;
    }

    &:last-child {
        border-bottom: 0;
    }

    // &:hover {
    //     background: #7c7c7c;
    // }

    .title {
        margin-bottom: 8px;
        font-weight: normal;
    }

    .description {
        font-size: 12px;
        line-height: 1.5;
    }

    .dateTime {
        margin-top: 4px;
        font-size: 12px;
        line-height: 1.5;
    }

    .extra {
        float: right;
        margin-top: -1.5px;
        margin-right: 0;
        color: #000000;
        font-weight: normal;
    }
}

.bottomBar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #23bebb;
    color: #ffffff;
    text-align: center;

    div {
        display: inline-block;
        width: 50%;
        cursor: pointer;
        transition: all 0.3s;
        user-select: none;
        text-align: center;
    
        &:only-child {
          width: 100%;
        }

        &:not(:only-child):last-child {
          border-left: 1px solid #e9e9e9;
        }
    }
}

.infiniteScrollContainer {
    overflow: auto;
    height: 80vh;
}

.emptyList {
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    flex-direction: column;
    display: inline-flex;
    width: 100%;
    height: 300px;

    .emptyIconContainer {
        color: $unselectedTextColor;
        font-size: 60px;
    }

    .emptyTextContainer {
        color: $unselectedTextColor;
        font-size: 24px;
        font-weight: bold;
    }
}

.separator {
    height: 0.5;
    background-color: rgba(0,0,0,0.4);
}

:global .loadsListTimeline {
    .ant-timeline-item-last > .ant-timeline-item-content {
        min-height: 0;
    }
}

:global .loadsListTimelineItem {
    padding: 0px;
    font-size: 10px;
    line-height: 1.2;

    .ant-timeline-item-tail {
        position: absolute;
        top: 10px;
        left: 2px;
        height: calc(100% - 15px);
        border-left: 5px dotted #f0f0f0;
    }

    .ant-timeline-item-head {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 100px;
    }
}

.cardItemContainer {
    //display: table-cell;
    //flex: 1;
    margin-right: 24px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 300px;
    height: 200px;
    // &:nth-of-type(2) {
    //     margin-left: 60px;
    // }
    // &:nth-last-of-type(2) {
    //     margin-right: 60px;
    // }
}

.cardItemContainerCollapsed {
    height: 90px;
}

.cardItemInfiniteScrollContainer {
    overflow: scroll hidden;
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 38px;
    margin-right: 38px;
    margin-bottom: 10px;
    position: relative;
}

.buttonContainer {
    position: relative;
    width: 30px;
    height: 0;
    left: 0;
    top: 0;
    z-index: 500;
}

.leftButtonContainer {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.rightButtonContainer {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.hasListActions {
    .leftButtonContainer {
        top: 107px;
    }

    .rightButtonContainer {
        top: 107px;
    }

    .cardItemInfiniteScrollContainer {
        padding-top: 45px
    }
}

.scrollButton {
    align-self: center;
    justify-self: center;
    flex: 1;
    height: 30px;
    width: 30px;
    opacity: 0.5;
}

.hidden { display:none; }

.loadDetails {
    display: flex;
    margin-top: 12px;

    .loadDate {
        width: 40px;
        margin-right: 12px;
    }

    .loadRoute {
        flex: 1;
    }
}

:global .selectedLoadsListCard {
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    background-color: $selectedCardBackgroundColor !important;
    color: $selectedTextColor !important;
    border: 3px solid $selectedBorderColor;
    cursor: pointer;

    .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $selectedTextColor !important;
    }

    .subtitle {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: $selectedTextColor !important;

        a {
            color: $selectedTextColor !important;

            &:hover {
                color: $selectedTextColor !important;
            }
        }
    }

    .stopDetails {
        margin-bottom: 12px;

        .locationDetails {
            font-size: 14px;
            line-height: 22px;
        }
    
        .apptDetails {
            font-size: 10px;
            line-height: 22px;
            opacity: 0.65;
        }
    }

    .stopDetails:last-of-type {
        margin-bottom: 0px;
    }

    .loadsListTimelineItem {
        color: $selectedTextColor !important;
    }

    .ant-card-actions {
        margin: 0;
        padding: 0;
        list-style: none;
        background: transparent;
        border-top: 1px solid #f0f0f0;
        position: absolute;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;

        li {
            color: $selectedTextColor !important;

            span {
                color: $selectedTextColor !important;

                &:hover {
                    color: $selectedTextColor !important;
                }

                .ant-btn {
                    span {
                        color: $unselectedTextColor !important;
                    }
                }
            }
        }
    }
  
    &:hover {
        background-color: $selectedCardBackgroundColor !important;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

:global .unselectedLoadsListCard {
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    background-color: $unselectedCardBackgroundColor !important;
    color: $unselectedTextColor !important;
    border: 3px solid $unselectedBorderColor;
    cursor: pointer;

    .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $unselectedTextColor !important;
        opacity: 0.85;
    }

    .subtitle {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: $unselectedTextColor !important;
        opacity: 0.65;

        
        a {
            color: $unselectedTextColor !important;
            opacity: 0.65;

            &:hover {
                color: $unselectedTextColor !important;
                opacity: 0.65;
            }
        }
    }

    .stopDetails {
        margin-bottom: 12px;

        .locationDetails {
            font-size: 14px;
            line-height: 22px;
        }
    
        .apptDetails {
            font-size: 10px;
            line-height: 22px;
        }
    }

    .stopDetails:last-of-type {
        margin-bottom: 0px;
    }

    .loadsListTimelineItem {
        color: $unselectedTextColor !important;
    }

    .ant-card-actions {
        margin: 0;
        padding: 0;
        list-style: none;
        background: transparent;
        border-top: 1px solid #f0f0f0;
        position: absolute;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;

        li {
            color: $unselectedTextColor !important;

            span {
                color: $unselectedTextColor !important;

                &:hover {
                    color: $unselectedTextColor !important;
                }

                .ant-btn {
                    span {
                        color: $unselectedTextColor !important;
                    }
                }
            }
        }
    }

    &:hover {
        background-color: $unselectedCardBackgroundColor !important;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

:global .collapsedListCard {
    height: 90px;
}

.columnNoBreak {
  white-space: nowrap;
}

.calendarDate {
    width: 40px;

    .monthContainer {
        width: 40px;
        text-align: center;
        margin-bottom: 5px;

        span {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .calendarContainer {
        position: absolute;
        align-items: center;
        justify-content: center;

        .calendarIcon {
            width: 40px;
            height: 40px;
        }
    }

    .dayContainer {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 40px;
        line-height: 40px;
        height: 40px;
        top: 4px;
        text-align: center;

        span {
            font-weight: bold;
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }