.Logo {
  background-color: #06152a;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;

  img {
    height: 100%;
  }
}

.Logo-Large {
  box-sizing: border-box;
  padding: 8px;
  border-radius: 5px;

  img {
    height: 100%;
  }
}

