$blueBackground: #334353;
$whiteBackground: #FFFFFF;
$greenBackground: #F6FFED;
$redBackground: #FFE4E2;
$yellowBackground: #FFFBE6;
$grayBackground: #E9E9E9;
$irisGreen: #23BEBB;
$grayText: #979797;
$blackText: #000000;
$whiteText: #FFFFFF;
$grayBorder: #BFBFBF;

$cancelledBackground: $grayBackground;
$approvedBackground: $redBackground;
$completedBackground: $greenBackground;
$hoverBackground: #fafafa;

.cancelledRow {
    background-color: $cancelledBackground !important;
    background: $cancelledBackground !important;
  
    &:hover {
      background-color: $cancelledBackground !important;
      background: $cancelledBackground !important;
    }
}

.approvedRow {
    background-color: $approvedBackground !important;
    background: $approvedBackground !important;
  
    &:hover {
      background-color: $approvedBackground !important;
      background: $approvedBackground !important;
    }
}

.completedRow {
    background-color: $completedBackground !important;
    background: $completedBackground !important;
  
    &:hover {
      background-color: $completedBackground !important;
      background: $completedBackground !important;
    }
}

.pendingRow {
    // background-color: transparent !important;
    // background: transparent !important;

    &:hover {
      background-color: $hoverBackground !important;
      background: $hoverBackground !important;
    }
}


.invoiceTitle h2, .invoiceTitle h3 {
    display: inline-block;
}

.trNoLine {
    border-top: none;
}

.noLine {
    border-bottom: none;
}

.thickLine {
    border-top: 2px solid;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    color: #212529;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    border-color: grey;
}

.table > thead > tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.table > thead > tr > td {
    vertical-align: top;
    line-height: 1.5;
    padding: 5px;
}

.table > tbody > tr > td {
    vertical-align: top;
    line-height: 1.5;
    padding: 5px;
    border-top: 1px solid #ddd;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

@media (max-width: 576px) {
    .card {
        overflow: visible;
        min-width: 200px;
    }
}